import { act } from "react-dom/test-utils";
import {
  FETCH_DRESSES_TO_ORDER_ENQUIRY_FAILURE,
  FETCH_DRESSES_TO_ORDER_ENQUIRY_SUCCESS,
  FETCH_DRESSES_TO_ORDER_ENQUIRY_REQUEST,
  DELETE_DRESSES_TO_ORDER_ENQUIRY_REQUEST,
  DELETE_DRESSES_TO_ORDER_ENQUIRY_SUCCESS,
  DELETE_DRESSES_TO_ORDER_ENQUIRY_FAILURE,
    MULTIPLE_DELETE_DRESSES_TO_ORDER_ENQUIRY_SUCCESS,
} from "../actions/dressestoorderenquiryActions";

// Initial state
const initialState = {
  enquiry: [],
  loading: false,
  error: null,
};

// Reducer
const dressestoorderenquiryReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_DRESSES_TO_ORDER_ENQUIRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
  
      case DELETE_DRESSES_TO_ORDER_ENQUIRY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      
      case FETCH_DRESSES_TO_ORDER_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: action.payload,
      };
    
      
      case DELETE_DRESSES_TO_ORDER_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        enquiry: state.enquiry.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_DRESSES_TO_ORDER_ENQUIRY_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedEnquiry = state.enquiry.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          enquiry: updatedEnquiry,
        };

        
      
      case FETCH_DRESSES_TO_ORDER_ENQUIRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case DELETE_DRESSES_TO_ORDER_ENQUIRY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    
    default:
      return state;
  }
};

export default dressestoorderenquiryReducer;
