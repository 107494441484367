import {React , useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { fetchFeedbackRequest, deleteFeedbackRequest, multipleDeleteFeedbackRequest } from '../../redux_store/actions/feedbacksActions';
import { useDispatch , useSelector } from 'react-redux';
import { BASE_URL } from '../../apis/url_constants';
import { ColorRing } from 'react-loader-spinner';
import SweetAlert from 'react-bootstrap-sweetalert';


const FeedBack = () => {
    const [showAlert, setShowAlert] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();

    const { feedback, loading, error } = useSelector(
        (state) => state.feedback
      );

    useEffect(() => {
        dispatch(fetchFeedbackRequest());
      }, [dispatch]);

      const handleMulitpleDelete = () => {

        const confirmAlert = (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => { 
                const selectedIds = selectedRows.map(row => row.id);
                dispatch(multipleDeleteFeedbackRequest(selectedIds));
                setSelectedRows([]);
                hideAlert();
            }}
              onCancel={hideAlert}
            >
              This action cannot be undone.
            </SweetAlert>
          );
        
          setShowAlert(confirmAlert);
      };

      const handleDelete = (row) => {
        const confirmAlert = (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes, delete it!"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title="Are you sure?"
            onConfirm={() => handleConfirmDelete(row)}
            onCancel={hideAlert}
          >
            This action cannot be undone.
          </SweetAlert>
        );
      
        setShowAlert(confirmAlert);
      };

      
      const handleConfirmDelete = (row) => {
        dispatch(deleteFeedbackRequest(row.id));
        hideAlert();
      };
      
      const hideAlert = () => {
        setShowAlert(null);
      };

      const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
      };

      
      const ExpandedComponent = ({ data }) => {
        return (
          <>
          <div className='p-4'>
          <h6 style={{fontWeight: '900'}}>FeedBacks</h6>
          <p>{data.message}</p> 
          </div> 
          </>
        )
      };

      const formatDate = (datetimeString) => {
        const date = new Date(datetimeString);
        const day = date.getDate(); // Get the day (1-31)
        const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to match the desired range (1-12)
        const year = date.getFullYear(); // Get the full year
    
        // Format the components as needed (e.g., zero padding)
        const formattedDate = `${day}-${month}-${year}`;
    
        return formattedDate;
      };

      const columns = feedback && feedback.length > 0 ? Object.keys(feedback[0]).map((key) => {

        if (key === 'createdAt') {
          return {
            name: 'Created At',
            selector: (row) => formatDate(row[key]), // Format the date here
            sortable: true,
          };
          }

          if (key === 'priceishigh') {
              return {
                  name: key.charAt(0).toUpperCase() + key.slice(1),
                  cell: (row) => (
                      <input type='checkbox'
                          checked={row[key]}
                          className='form-check-input'
                          disabled
                      />
                  ),
                  sortable: true,
              };
          }

          if (key === 'iwasjustbrowsing') {
              return {
                  name: key.charAt(0).toUpperCase() + key.slice(1),
                  cell: (row) => (
                      <input type='checkbox'
                          checked={row[key]}
                          className='form-check-input'
                          disabled
                      />
                  ),
                  sortable: true,
              };
          }

          if (key === 'shippingisexpensive') {
              return {
                  name: key.charAt(0).toUpperCase() + key.slice(1),
                  cell: (row) => (
                      <input type='checkbox'
                          checked={row[key]}
                          className='form-check-input'
                          disabled
                      />
                  ),
                  sortable: true,
              };
          }

          if (key === 'ineedtheitemfaster') {
              return {
                  name: key.charAt(0).toUpperCase() + key.slice(1),
                  cell: (row) => (
                      <input type='checkbox'
                          checked={row[key]}
                          className='form-check-input'
                          disabled
                      />
                  ),
                  sortable: true,
              };
          }

          if (key === 'websiteishardtouse') {
              return {
                  name: key.charAt(0).toUpperCase() + key.slice(1),
                  cell: (row) => (
                      <input type='checkbox'
                          checked={row[key]}
                          className='form-check-input'
                          disabled
                      />
                  ),
                  sortable: true,
              };
          }
        
        return {
          name: key.charAt(0).toUpperCase() + key.slice(1),
          selector: (row) => row[key],
          sortable: true,
        };
      }) : [];

      const columnsToRemove = ['Id' ,  'LastModified'];
        const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

        const actionsColumn = {
            name: 'Actions',
            cell: (row) => (
              <>
                <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
              </>
            ),
            sortable: false,
          };

          const modifiedColumns = [ actionsColumn, ...filteredColumns,];


  return (
    <>
    <div className='col-12'>
        <div className="card">
                <div className='card-header d-flex justify-content-between align-items-center'>
                    <h3>FeedBacks</h3>
                    <div className='d-flex align-items-center'>
                    {selectedRows.length > 0 && (
                        <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
                    )}
                    </div>
                </div>
                <div className='card-body'>
                <DataTable
                    pagination
                    columns={modifiedColumns}
                    data={feedback}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    selectableRows
                    selectableRowsHighlight
                    onSelectedRowsChange={handleRowSelected}
                    selectedRows={selectedRows}
                    progressPending={loading}
                    progressComponent={<ColorRing/>}
                    responsive={true}
                />
                {showAlert}
                </div>
            </div>
        </div>
            
    </>
  )
}

export default FeedBack