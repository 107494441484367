import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_BOUTIQUE_URL, ADD_BOUTIQUE_URL, DELETE_BOUTIQUE__URL, UPDATE_BOUTIQUE__URL, MULTIPLE_BOUTIQUE_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_BOUTIQUE_REQUEST = 'FETCH_BOUTIQUE_REQUEST';
export const FETCH_BOUTIQUE_SUCCESS = 'FETCH_BOUTIQUE_SUCCESS';
export const FETCH_BOUTIQUE_FAILURE = 'FETCH_BOUTIQUE_FAILURE';

export const ADD_BOUTIQUE_REQUEST = 'ADD_BOUTIQUE_REQUEST';
export const ADD_BOUTIQUE_SUCCESS = 'ADD_BOUTIQUE_SUCCESS';
export const ADD_BOUTIQUE_FAILURE = 'ADD_BOUTIQUE_FAILURE';

export const DELETE_BOUTIQUE_REQUEST = 'DELETE_BOUTIQUE_REQUEST';
export const DELETE_BOUTIQUE_SUCCESS = 'DELETE_BOUTIQUE_SUCCESS';
export const DELETE_BOUTIQUE_FAILURE = 'DELETE_BOUTIQUE_FAILURE';

export const MULTIPLE_DELETE_BOUTIQUE_SUCCESS = 'MULTIPLE_DELETE_BOUTIQUE_SUCCESS';

export const UPDATE_BOUTIQUE_REQUEST = 'UPDATE_BOUTIQUE_REQUEST';
export const UPDATE_BOUTIQUE_SUCCESS = 'UPDATE_BOUTIQUE_SUCCESS';
export const UPDATE_BOUTIQUE_FAILURE = 'UPDATE_BOUTIQUE_FAILURE';






export const fetchBoutiqueRequest = (id) => async (dispatch) => {
    dispatch({ type: FETCH_BOUTIQUE_REQUEST });
  const token = localStorage.getItem("token");

  try {
      let endpoint = GET_BOUTIQUE_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const boutique = response.data;
      dispatch({
        type: FETCH_BOUTIQUE_SUCCESS,
        payload: [boutique],
      });
    } else {
      const boutiqueList = response.data;
      dispatch({
        type: FETCH_BOUTIQUE_SUCCESS,
        payload: boutiqueList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_BOUTIQUE_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchBoutiqueSuccess = (boutique) => ({
    type: FETCH_BOUTIQUE_SUCCESS,
    payload: boutique,
  });
  
  export const fetchBoutiqueFailure = (error) => ({
    type: FETCH_BOUTIQUE_FAILURE,
    payload: error,
  });
  
  export const addBoutiqueRequest = (boutique, navigate) => async (dispatch) => {
    dispatch({ type: ADD_BOUTIQUE_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const formData = new FormData();
        formData.append('Name', boutique.Name);
        formData.append('Slug', boutique.Slug);
        formData.append('SortOrder', boutique.SortOrder);
        formData.append('Image', boutique.Image);        


      const response = await axios.post(ADD_BOUTIQUE_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          },
      });

      dispatch({ type: ADD_BOUTIQUE_SUCCESS, payload: [response.data] });
      navigate('/boutique'); 

  } catch (error) {
    dispatch({
      type: ADD_BOUTIQUE_FAILURE,
      payload: error.message,
    });
    toast.error("Something went Wrong!, While Adding Boutique.")
  }
  };
  
  export const addBoutiqueSuccess = () => ({
    type: ADD_BOUTIQUE_SUCCESS,
  });
  
  export const addBoutiqueFailure = (error) => ({
    type: ADD_BOUTIQUE_FAILURE,
    payload: error,
  });


  export const deleteBoutiqueRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_BOUTIQUE_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_BOUTIQUE__URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_BOUTIQUE_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_BOUTIQUE_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Boutique');
    }
  };

  export const deleteBoutiqueSuccess = () => ({
    type: DELETE_BOUTIQUE_SUCCESS,
  });
  
  export const deleteBoutiqueFailure = (error) => ({
    type: DELETE_BOUTIQUE_FAILURE,
    payload: error,
  });


  export const multipleDeleteBoutiqueRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_BOUTIQUE_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_BOUTIQUE_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_BOUTIQUE_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_BOUTIQUE_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateBoutiqueRequest = (boutique, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_BOUTIQUE_REQUEST });
    const token = localStorage.getItem('token');
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', boutique.Name);
        formData.append('Slug', boutique.Slug);
        formData.append('SortOrder', boutique.SortOrder);
        formData.append('Image', boutique.Image); 

      const response = await axios.put(`${UPDATE_BOUTIQUE__URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_BOUTIQUE_SUCCESS, payload: [response.data] });
      navigate('/boutique'); 

    } catch (error) {
      dispatch({
        type: UPDATE_BOUTIQUE_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Boutique');
    }
  };
  

  
  
