import { act } from "react-dom/test-utils";
import {
  FETCH_FEEDBACK_FAILURE,
  FETCH_FEEDBACK_SUCCESS,
  FETCH_FEEDBACK_REQUEST,
  DELETE_FEEDBACK_REQUEST,
  DELETE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK_FAILURE,
  MULTIPLE_DELETE_FEEDBACK_SUCCESS,
} from "../actions/feedbacksActions";

// Initial state
const initialState = {
  enquiry: [],
  loading: false,
  error: null,
};

// Reducer
const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_FEEDBACK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
  
      case DELETE_FEEDBACK_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

      
      case FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedback: action.payload,
      };
    
      
      case DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
          loading: false,
          feedback: state.feedback.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_FEEDBACK_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updateFeedback = state.feedback.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
            loading: false,
            feedback: updateFeedback,
        };

        
      
      case FETCH_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case DELETE_FEEDBACK_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

    
    default:
      return state;
  }
};

export default feedbackReducer;
