import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-form-wizard-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import * as Yup from "yup";
import { BASE_URL } from "../../apis/url_constants";
import {
  addProductRequest,
  deleteProductBannerImageRequest,
  deleteProductImageRequest,
  fetchProductRequest,
  updateProductRequest,
} from "../../redux_store/actions/productActions";

import { GET_TAXES_URL } from "../../apis/url_constants";
import { GET_PRODUCT_URL } from "../../apis/url_constants";
import { GET_CATEGORY_URL } from "../../apis/url_constants";
import { GET_SUBCATEGORY_URL } from "../../apis/url_constants";
import { GET_BOUTIQUE_URL } from "../../apis/url_constants";

const AddProduct = () => {
  const { id } = useParams();
  const editMode = Boolean(id);

  const location = useLocation();
  const viewMode = location.pathname.includes("/View/");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { product, loading, error } = useSelector((state) => state.product);
  const { category, cloading, cerror } = useSelector((state) => state.category);
  const { subCategory, sCloading, sCerror } = useSelector(
    (state) => state.subCategory
  );
  const { taxes, tloading, terror } = useSelector((state) => state.taxes);

  const [categoryList , setCategoryList] = useState([]);
  const [boutiqueList , setBoutiqueList] = useState([]);
  const [subCategoryList , setSubCategoryList] = useState([]);
  const [productList , setProductList] = useState([]);
  const [taxList , setTaxList] = useState([]);

  const [tags, setTags] = useState([]);
  const [producttags, setProductTags] = useState([]);

  const [optionvaluetags, setOptionValueTags] = useState([]);

  const [initialValues, setInitialValues] = useState({
    Name: "",
    Slug: "",
    Image: null,
    Images: [],
    BannerImages: [],
    Description: "",
    ShortDescription: "",
    HasOptions: false,
    ProductCategories: [],
    ProductSubCategories: [],
    ProductTaxes: [],
    BasePrice: "",
    DiscountAmount: "",
    ProductTags: "",
    AvailableQty: 0,
    FreeShipping: false,
    Status: false,
    EnableTaxes: false,
    OutOfStock: false,
    InStock: false,
    Title: "",
    MetaDescription: "",
    MetaKeywords: "",
    RelatedProducts: [],
    SKU: "",
    SizeChart: null,
    SortOrder: 0,
    GuaranteeWarranty: "",
    BuyNow: false,
    AddToCart: false,
    Wishlist: false,
    Enquiry: false,
    Whatsapp: false,
    HasFAQs: false,
    BestSeller:false,
    NewArrival:false,
    Trending:false,
    Sales:false,
    DressesOrder:false,
    BoutiqueId:null,
    Options: [],
    ProductFaqs: [],
    FinalPrice: "",
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Product name is required"),
    Slug: Yup.string(),
    Image: Yup.mixed().notRequired(),
    Images: Yup.array().of(Yup.mixed()),
    BannerImages: Yup.array().of(Yup.mixed()),
    Description: Yup.string(),
    ShortDescription: Yup.string().max(200, "Short description must not exceed 200 characters"),
    HasOptions: Yup.boolean(),
    ProductCategories: Yup.array().of(Yup.number()),
    ProductSubCategories: Yup.array().of(Yup.number()),
    ProductTaxes: Yup.array().of(Yup.number()),
    BasePrice: Yup.number(),
    DiscountAmount: Yup.number(),
    AvailableQty: Yup.number(),
    ProductTags: Yup.string(),
    FreeShipping: Yup.boolean(),
    Status: Yup.boolean(),
    EnableTaxes: Yup.boolean(),
    OutOfStock: Yup.boolean(),
    InStock: Yup.boolean(),
    Title: Yup.string(),
    MetaDescription: Yup.string(),
    MetaKeywords: Yup.string(),
    RelatedProducts: Yup.array().of(Yup.number()),
    SKU: Yup.string(),
    SizeChart: Yup.mixed().notRequired(),
    SortOrder: Yup.number(),
    GuaranteeWarranty: Yup.string(),
    BuyNow: Yup.boolean(),
    AddToCart: Yup.boolean(),
    Wishlist: Yup.boolean(),
    Enquiry: Yup.boolean(),
    Whatsapp: Yup.boolean(),
    HasFAQs: Yup.boolean(),
    BestSeller: Yup.boolean(),
    NewArrival: Yup.boolean(),
    Trending: Yup.boolean(),
    Sales: Yup.boolean(),
    DressesOrder: Yup.boolean(),
    BoutiqueId: Yup.number().required("Boutique Name is Required"),
    Options: Yup.array().of(
      Yup.object().shape({
        OptionName: Yup.string().required("Option Name is Required"),
        OptionValue: Yup.string(),
      })
    ),
    ProductFaqs: Yup.array().of(
      Yup.object().shape({
        Question: Yup.string().required("Question is Required"),
        Answer: Yup.string().required("Answer is Required"),
        SortOrder: Yup.string(),
      })
    ),
  });

  const handleSubmit = (values) => {
    editMode
      ? dispatch(updateProductRequest(values, id, navigate))
      : dispatch(addProductRequest(values, navigate));
  };

  useEffect(() => {
    if (editMode) {
      dispatch(fetchProductRequest(id));
    }
  }, [editMode, dispatch, id]);



  useEffect(() => {

    const fetchData = async () => {
      try {
        let endpoint = GET_TAXES_URL;
        const token = localStorage.getItem('token');
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaxList(response.data); // Assuming that the response data is an array of tax items

        const categoryEndpoint = GET_CATEGORY_URL;
        const categoryResponse = await axios.get(categoryEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategoryList(categoryResponse.data);

        // Fetch subcategory data
        const boutiqueEndpoint = GET_BOUTIQUE_URL;
        const boutiqueResponse = await axios.get(boutiqueEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBoutiqueList(boutiqueResponse.data);

          // Fetch subcategory data
        const subcategoryEndpoint = GET_SUBCATEGORY_URL;
        const subcategoryResponse = await axios.get(subcategoryEndpoint, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        setSubCategoryList(subcategoryResponse.data);

        const productEndpoint = GET_PRODUCT_URL;
        const productResponse = await axios.get(productEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProductList(productResponse.data);

      } catch (error) {
        // Handle error if needed
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    if (editMode && product && product.length > 0) {
      const filteredProduct = product[0];

      if (
        filteredProduct.metaKeywords !== null &&
        filteredProduct.metaKeywords !== undefined
      ) {
        const tagsArray = filteredProduct.metaKeywords
          .split(",")
          .map((tag) => tag.trim());
        setTags(tagsArray);
      } else {
        setTags([]);
      }

      if (
        filteredProduct.productTags !== null &&
        filteredProduct.productTags !== undefined
      ) {
        const tagsArray = filteredProduct.productTags
          .split(",")
          .map((tag) => tag.trim());
        setProductTags(tagsArray);
      } else {
        setProductTags([]);
      }

      if (
        filteredProduct &&
        filteredProduct.options &&
        filteredProduct.options.length &&
        filteredProduct.options.every((option) => option.values)
      ) {
        const optionArray = filteredProduct.options.map((option) =>
          option.values.map((tag) => tag.text)
        );
        setOptionValueTags(optionArray);
      }
      
      

      setInitialValues({
        Name: filteredProduct.name !== null ? filteredProduct.name : "",
        Slug: filteredProduct.slug !== null ? filteredProduct.slug : "",
        Image: null,
        Images: [],
        BannerImages: [],
        Description:
          filteredProduct.description !== null
            ? filteredProduct.description
            : "",
        ShortDescription: filteredProduct.shortDescription
          ? filteredProduct.shortDescription
          : "",
        HasOptions: filteredProduct.hasOptions
          ? filteredProduct.hasOptions
          : false,
        ProductCategories: filteredProduct.productCategories
          ? filteredProduct.productCategories.map(
              (category) => category.categoryId
            )
          : [],
        ProductSubCategories: filteredProduct.productSubCategories
          ? filteredProduct.productSubCategories.map(
              (subCategory) => subCategory.subCategoryId
            )
          : [],
        ProductTaxes: filteredProduct.productTaxes
          ? filteredProduct.productTaxes.map((tax) => tax.taxId)
          : [],
        BasePrice:
          filteredProduct.basePrice !== null ? filteredProduct.basePrice : "",
        DiscountAmount:
          filteredProduct.discountAmount !== null
            ? filteredProduct.discountAmount
            : "",
        ProductTags: filteredProduct.productTags
          ? filteredProduct.productTags
          : "",
        FreeShipping: filteredProduct.freeShipping
          ? filteredProduct.freeShipping
          : false,
        Status: filteredProduct.status ? filteredProduct.status : false,
        EnableTaxes: filteredProduct.enableTaxes
          ? filteredProduct.enableTaxes
          : false,
        OutOfStock: filteredProduct.outOfStock
          ? filteredProduct.outOfStock
          : false,
        InStock: filteredProduct.inStock ? filteredProduct.inStock : false,
        Title: filteredProduct.title !== null ? filteredProduct.title : "",
        MetaDescription:
          filteredProduct.metaDescription !== null
            ? filteredProduct.metaDescription
            : "",
        MetaKeywords:
          filteredProduct.metaKeywords !== null
            ? filteredProduct.metaKeywords
            : "",
        RelatedProducts: filteredProduct.relatedProducts
          ? filteredProduct.relatedProducts.map(
              (relatedProduct) => relatedProduct.relatedId
            )
          : [],
        SKU: filteredProduct.sku !== null ? filteredProduct.sku : "",
        SizeChart: null,
        SortOrder:
          filteredProduct.sortOrder !== null ? filteredProduct.sortOrder : 0,
        GuaranteeWarranty: filteredProduct.guaranteeWarranty
          ? filteredProduct.guaranteeWarranty
          : "",
        BuyNow: filteredProduct.buyNow ? filteredProduct.buyNow : false,
        AddToCart: filteredProduct.addToCart
          ? filteredProduct.addToCart
          : false,
        Wishlist: filteredProduct.wishlist ? filteredProduct.wishlist : false,
        Enquiry: filteredProduct.enquiry ? filteredProduct.enquiry : false,
        Whatsapp: filteredProduct.whatsapp ? filteredProduct.whatsapp : false,
        HasFAQs: filteredProduct.hasFAQs ? filteredProduct.hasFAQs : false,
        BestSeller: filteredProduct.bestSeller ? filteredProduct.bestSeller : false,
        NewArrival: filteredProduct.newArrival ? filteredProduct.newArrival : false,
        Trending: filteredProduct.trending ? filteredProduct.trending : false,
          Sales: filteredProduct.sales ? filteredProduct.sales : false,
          DressesOrder: filteredProduct.dressesOrder ? filteredProduct.dressesOrder : false,
        Options: filteredProduct.options ? filteredProduct.options.map( (option) => ({
          OptionName: option.name,
          OptionValue: option.values ? option.values.map((value) => value.text).join(", ") : "",
        })) :  [],
        ProductFaqs: filteredProduct.productFaqs ? filteredProduct.productFaqs.map((faq) => ({
          Question : faq.question,
          Answer: faq.answer,
          SortOrder: faq.sortOrder,
        })) :  [],
        AvailableQty: filteredProduct.availableQty ? filteredProduct.availableQty : 0,
        FinalPrice: filteredProduct.finalPrice ? filteredProduct.finalPrice : "",
        BoutiqueId: filteredProduct.boutiqueId ? filteredProduct.boutiqueId : 0,
      });
    }
  }, [editMode, product]);

  const handleDelete = (ImageId) => {
    dispatch(deleteProductImageRequest(ImageId));
  };

  const handleBannerDelete = (ImageId) => {
    dispatch(deleteProductBannerImageRequest(ImageId));
  };

  console.log(boutiqueList)

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h3>
              {viewMode ? (
                <span>View Product</span>
              ) : !viewMode && editMode ? (
                <span>Edit Product</span>
              ) : (
                <span>Add Product</span>
              )}
            </h3>
          </div>
          <div className="card-body pb-0">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, initialValues, values }) => (
              
                <Form>
                  <div className="row">
                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Product Description
                    </h5>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Name"
                          className="form-label fw-semibold"
                        >
                          Name
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-badge-cc fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Name"
                            name="Name"
                            disabled={viewMode}
                            onChange={(event) => {
                              const name = event.target.value;
                              setFieldValue("Name", name);
                              setFieldValue(
                                "Slug",
                                name
                                  .toLowerCase()
                                  .replace(/[^a-zA-Z0-9 ]/g, "")
                                  .replace(/ /g, "-")
                              );
                            }}
                            className="form-control border-0 ps-2"
                            placeholder="Enter Name here"
                          />
                        </div>
                        <ErrorMessage
                          name="Name"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Slug"
                          className="form-label fw-semibold"
                        >
                          Slug
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Slug"
                            name="Slug"
                            className="form-control border-0 ps-2"
                            placeholder="Slug"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Slug"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label htmlFor="SKU" className="form-label fw-semibold">
                          SKU
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-2fa fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="SKU"
                            name="SKU"
                            className="form-control border-0 ps-2"
                            placeholder="SKU"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="SKU"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="ProductCategories"
                          className="form-label fw-semibold"
                        >
                          Product Categories
                        </label>
                        <Field name="ProductCategories">
                          {({ field, form }) => (
                            <Select
                              id="ProductCategories"
                              name="ProductCategories"
                              options={categoryList.map((category) => ({
                                value: category.id,
                                label: category.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={categoryList
                                .map((category) => ({
                                  value: category.id,
                                  label: category.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "ProductCategories",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="ProductCategories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="ProductSubCategories"
                          className="form-label fw-semibold"
                        >
                          Product SubCategories
                        </label>
                        <Field name="ProductSubCategories">
                          {({ field, form }) => (
                            <Select
                              id="ProductSubCategories"
                              name="ProductSubCategories"
                              options={subCategoryList.map((subCategory) => ({
                                value: subCategory.id,
                                label: subCategory.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={subCategoryList
                                .map((subCategory) => ({
                                  value: subCategory.id,
                                  label: subCategory.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "ProductSubCategories",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="ProductSubCategories"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="RelatedProducts"
                          className="form-label fw-semibold"
                        >
                          Related Products
                        </label>
                        <Field name="RelatedProducts">
                          {({ field, form }) => (
                            <Select
                              id="RelatedProducts"
                              name="RelatedProducts"
                              options={productList.map((product) => ({
                                value: product.id,
                                label: product.name,
                              }))}
                              isMulti
                              isDisabled={viewMode}
                              value={productList
                                .map((product) => ({
                                  value: product.id,
                                  label: product.name,
                                }))
                                .filter((option) =>
                                  field.value.includes(option.value)
                                )}
                              onChange={(selectedOptions) => {
                                form.setFieldValue(
                                  "RelatedProducts",
                                  selectedOptions
                                    ? selectedOptions.map(
                                        (option) => option.value
                                      )
                                    : []
                                );
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="RelatedProducts"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="BoutiqueId"
                          className="form-label fw-semibold"
                        >
                          Boutique
                        </label>
                        <div className="input-group border rounded-1">
                          <Field
                            as="select"
                            id="BoutiqueId"
                            name="BoutiqueId"
                            className="form-select border-0 ps-2"
                            disabled={viewMode}
                          >
                            <option value="" selected>
                              Select Boutique
                            </option>
                            {boutiqueList.map((boutique) => (
                              <option key={boutique.id} value={boutique.id}>
                                {boutique.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <ErrorMessage
                          name="BoutiqueId"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Image"
                          className="form-label fw-semibold"
                        >
                          Image
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Image"
                            name="Image"
                            disabled={viewMode}
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("Image", file || null);
                            }}
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="Image"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {product &&
                          product.length > 0 &&
                          !loading &&
                          product[0].image ? (
                            <div className="py-3">
                              <img
                                src={`${BASE_URL}${product[0].image}`}
                                alt="Image"
                                style={{ height: "60px", width: "auto" }}
                              />
                            </div>
                          ) : loading || product === undefined ? (
                            <div>Loading image...</div>
                          ) : (
                            <div>No image available</div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="Images"
                          className="form-label fw-semibold"
                        >
                          Images
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="Images"
                            name="Images"
                            disabled={viewMode}
                            onChange={(event) => {
                              const files = event.target.files;
                              const imagesArray = Array.from(files);
                              setFieldValue("Images", imagesArray);
                            }}
                            className="form-control"
                            multiple
                          />
                        </div>
                        <ErrorMessage
                          name="Images"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {product &&
                          product.length > 0 &&
                          !loading &&
                          product[0].images ? (
                            <div className="py-3 d-flex flex-wrap">
                              {product[0].images.map((image, index) => (
                                <div className="position-relative">
                                  <img
                                    key={index}
                                    src={`${BASE_URL}${image.img}`}
                                    alt="Image"
                                    style={{ height: "60px", width: "auto" }}
                                    className="me-3 mb-3"
                                  />
                                  {!viewMode && (
                                    <i
                                      onClick={() => handleDelete(image.id)}
                                      className="ti ti-trash text-danger fs-6 px-2 position-absolute"
                                      style={{ bottom: "20px", right: "10px" }}
                                    ></i>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : loading || product === undefined ? (
                            <div>Loading images...</div>
                          ) : (
                            <div>No images available</div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="BannerImages"
                          className="form-label fw-semibold"
                        >
                          BannerImages
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="BannerImages"
                            name="BannerImages"
                            disabled={viewMode}
                            onChange={(event) => {
                              const files = event.target.files;
                              const imagesArray = Array.from(files);
                              setFieldValue("BannerImages", imagesArray);
                            }}
                            className="form-control"
                            multiple
                          />
                        </div>
                        <ErrorMessage
                          name="BannerImages"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {product &&
                          product.length > 0 &&
                          !loading &&
                          product[0].bannerImages ? (
                            <div className="py-3 d-flex flex-wrap">
                              {product[0].bannerImages.map((image, index) => (
                                <div className="position-relative">
                                  <img
                                    key={index}
                                    src={`${BASE_URL}${image.img}`}
                                    alt="Image"
                                    style={{ height: "60px", width: "auto" }}
                                    className="me-3 mb-3"
                                  />
                                  {!viewMode && (
                                    <i
                                      onClick={() =>
                                        handleBannerDelete(image.id)
                                      }
                                      className="ti ti-trash text-danger fs-6 px-2 position-absolute"
                                      style={{ bottom: "20px", right: "10px" }}
                                    ></i>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : loading || product === undefined ? (
                            <div>Loading Banner Images...</div>
                          ) : (
                            <div>No Banner available</div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="ShortDescription"
                          className="form-label fw-semibold"
                        >
                          ShortDescription
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-file-description fs-6"></i>
                          </span>
                          <Field
                            as="textarea"
                            id="ShortDescription"
                            name="ShortDescription"
                            className="form-control border-0 ps-2"
                            placeholder="ShortDescription"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="ShortDescription"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="Description"
                          className="form-label fw-semibold"
                        >
                          Description
                        </label>
                        <div className=" border rounded-1">
                          <CKEditor
                            name="Description"
                            id="Description"
                            disabled={viewMode}
                            editor={ClassicEditor}
                            data={initialValues.Description || ""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("Description", data);
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="Description"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Product Setting's
                    </h5>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="AvailableQty"
                          className="form-label fw-semibold"
                        >
                          Available Quantity
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-circle-number-1 fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="AvailableQty"
                            name="AvailableQty"
                            className="form-control border-0 ps-2"
                            placeholder="AvailableQty"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="AvailableQty"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="BasePrice"
                          className="form-label fw-semibold"
                        >
                          Base Price
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-receipt-2 fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="BasePrice"
                            name="BasePrice"
                            className="form-control border-0 ps-2"
                            placeholder="Base Price"
                            disabled={viewMode}
                            onChange={(event) => {
                              const basePrice = event.target.value;
                              const discountAmount = values.DiscountAmount || 0 ;
                              setFieldValue("BasePrice", basePrice);
                              setFieldValue(
                                "FinalPrice",
                                basePrice - discountAmount
                              );
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="BasePrice"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="DiscountAmount"
                          className="form-label fw-semibold"
                        >
                          Discount Amount
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-discount fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="DiscountAmount"
                            name="DiscountAmount"
                            className="form-control border-0 ps-2"
                            placeholder="Discount Amount"
                            disabled={viewMode}
                            onChange={(event) => {
                              const basePrice = values.BasePrice || 0;
                              const discountAmount = event.target.value;
                              setFieldValue("DiscountAmount", discountAmount);
                              basePrice > 0 ? 
                              setFieldValue(
                                "FinalPrice",
                                basePrice - discountAmount
                              ) : setFieldValue(
                                "FinalPrice",
                                "")
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="DiscountAmount"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="FinalPrice"
                          className="form-label fw-semibold"
                        >
                          Final Price
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-discount-check fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="FinalPrice"
                            name="FinalPrice"
                            className="form-control border-0 ps-2"
                            placeholder="Final Price"
                            disabled={true}
                          />
                        </div>
                        <ErrorMessage
                          name="FinalPrice"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="mb-4">
                        <label
                          htmlFor="GuaranteeWarranty"
                          className="form-label fw-semibold"
                        >
                          Guarantee / Warranty
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-circle-check fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="GuaranteeWarranty"
                            name="GuaranteeWarranty"
                            className="form-control border-0 ps-2"
                            placeholder="Guarantee / Warranty"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="GuaranteeWarranty"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <label
                          htmlFor="SizeChart"
                          className="form-label fw-semibold"
                        >
                          SizeChart
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-photo fs-6"></i>
                          </span>
                          <input
                            type="file"
                            id="SizeChart"
                            name="SizeChart"
                            disabled={viewMode}
                            onChange={(event) => {
                              const file = event.target.files[0];
                              setFieldValue("SizeChart", file || null);
                            }}
                            className="form-control"
                          />
                        </div>
                        <ErrorMessage
                          name="SizeChart"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>

                      {editMode && (
                        <>
                          {product &&
                          product.length > 0 &&
                          !loading &&
                          product[0].sizeChart ? (
                            <div className="py-3">
                              <img
                                src={`${BASE_URL}${product[0].sizeChart}`}
                                alt="Image"
                                style={{ height: "60px", width: "auto" }}
                              />
                            </div>
                          ) : loading || product === undefined ? (
                            <div>Loading Size Chart...</div>
                          ) : (
                            <div>No Size Chart available</div>
                          )}
                        </>
                      )}
                    </div>


                    <div className="col-md-4">
                      <div className="mb-3">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="EnableTaxes"
                            name="EnableTaxes"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="EnableTaxes"
                            className="fw-semibold form-check-label"
                          >
                            Enable Taxes
                          </label>
                        </div>
                        <ErrorMessage
                          name="EnableTaxes"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                      {values.EnableTaxes && (
                        <div className="mb-4">
                          {/* <label
                          htmlFor="ProductTaxes"
                          className="form-label fw-semibold"
                        >
                          Product Taxes
                        </label> */}
                          <Field name="ProductTaxes">
                            {({ field, form }) => (
                              <Select
                                id="ProductTaxes"
                                name="ProductTaxes"
                                options={taxList.map((tax) => ({
                                  value: tax.id,
                                  label: tax.name,
                                }))}
                                isMulti
                                isDisabled={viewMode}
                                value={taxList
                                  .map((tax) => ({
                                    value: tax.id,
                                    label: tax.name,
                                  }))
                                  .filter((option) =>
                                    field.value.includes(option.value)
                                  )}
                                onChange={(selectedOptions) => {
                                  form.setFieldValue(
                                    "ProductTaxes",
                                    selectedOptions
                                      ? selectedOptions.map(
                                          (option) => option.value
                                        )
                                      : []
                                  );
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="ProductTaxes"
                            component="div"
                            className="mt-1 error"
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="FreeShipping"
                            name="FreeShipping"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="FreeShipping"
                            className="fw-semibold form-check-label"
                          >
                            Free Shipping
                          </label>
                        </div>
                        <ErrorMessage
                          name="FreeShipping"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="InStock"
                            name="InStock"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="InStock"
                            className="fw-semibold form-check-label"
                          >
                            In Stock
                          </label>
                        </div>
                        <ErrorMessage
                          name="InStock"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="OutOfStock"
                            name="OutOfStock"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="OutOfStock"
                            className="fw-semibold form-check-label"
                          >
                            Out of Stock
                          </label>
                        </div>
                        <ErrorMessage
                          name="OutOfStock"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="Status"
                            name="Status"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="Status"
                            className="fw-semibold form-check-label"
                          >
                            Status
                          </label>
                        </div>
                        <ErrorMessage
                          name="Status"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Product Widget
                    </h5>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <Field
                            type="checkbox"
                            id="BestSeller"
                            name="BestSeller"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="BestSeller"
                            className="fw-semibold form-check-label"
                          >
                            BestSeller
                          </label>
                        </div>
                        <ErrorMessage
                          name="BestSeller"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <Field
                            type="checkbox"
                            id="NewArrival"
                            name="NewArrival"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="NewArrival"
                            className="fw-semibold form-check-label"
                          >
                            NewArrival
                          </label>
                        </div>
                        <ErrorMessage
                          name="NewArrival"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-4">
                        <div className="form-check form-switch">
                          <Field
                            type="checkbox"
                            id="Trending"
                            name="Trending"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="Trending"
                            className="fw-semibold form-check-label"
                          >
                            Trending
                          </label>
                        </div>
                        <ErrorMessage
                          name="Trending"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                                      </div>

                                      <div className="col-md-4">
                                          <div className="mb-4">
                                              <div className="form-check form-switch">
                                                  <Field
                                                      type="checkbox"
                                                      id="Sales"
                                                      name="Sales"
                                                      className="form-check-input"
                                                      disabled={viewMode}
                                                  />
                                                  <label
                                                      htmlFor="Sales"
                                                      className="fw-semibold form-check-label"
                                                  >
                                                      Sales
                                                  </label>
                                              </div>
                                              <ErrorMessage
                                                  name="Sales"
                                                  component="div"
                                                  className="mt-1 error"
                                              />
                                          </div>
                                      </div>

                                      <div className="col-md-4">
                                          <div className="mb-4">
                                              <div className="form-check form-switch">
                                                  <Field
                                                      type="checkbox"
                                                      id="DressesOrder"
                                                      name="DressesOrder"
                                                      className="form-check-input"
                                                      disabled={viewMode}
                                                  />
                                                  <label
                                                      htmlFor="DressesOrder"
                                                      className="fw-semibold form-check-label"
                                                  >
                                                      Dresses To Order
                                                  </label>
                                              </div>
                                              <ErrorMessage
                                                  name="DressesOrder"
                                                  component="div"
                                                  className="mt-1 error"
                                              />
                                          </div>
                                      </div>

                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Product Option's
                    </h5>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="HasOptions"
                            name="HasOptions"
                            className="form-check-input"
                            disabled={viewMode}
                            checked={values.HasOptions}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFieldValue("HasOptions", isChecked); // Update the value in Formik state
                              if (!isChecked) {
                                setFieldValue("Options", []); // Clear ProductFaqs array
                                setOptionValueTags([]);
                              } else {
                                setFieldValue("Options", [{ OptionName: "", OptionValue: "" }]); // Add a default FAQ entry
                                setOptionValueTags([[]]);
                              }
                            }}
                          />
                          <label
                            htmlFor="HasOptions"
                            className="fw-semibold form-check-label"
                          >
                            Has Options?
                          </label>
                        </div>
                        <ErrorMessage
                          name="HasOptions"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    {values.HasOptions &&
                      values.Options.map((option, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-3">
                            <div className="mb-4">
                              <label
                                htmlFor="OptionName"
                                className="form-label fw-semibold"
                              >
                                Option {index + 1}
                              </label>
                              <div className="input-group border rounded-1">
                                <span
                                  className="input-group-text bg-transparent px-6 border-0"
                                  id="basic-addon1"
                                >
                                  <i className="ti ti-link fs-6"></i>
                                </span>
                                <Field
                                  type="text"
                                  id={`OptionName${index}`} // Add index to id attribute
                                  name={`Options[${index}].OptionName`} // Add index to name attribute
                                  className="form-control border-0 ps-2"
                                  placeholder={`Option ${index + 1}`}
                                  value={values.Options[index].OptionName} // Use the value from optionFields for the initial value
                                  onChange={(e) =>
                                    setFieldValue(`Options[${index}].OptionName`, e.target.value)
                                  }
                                  disabled={viewMode}
                                />
                              </div>
                              <ErrorMessage
                                name={`Options[${index}].OptionName`}
                                component="div"
                                className="mt-1 error"
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="mb-4">
                              <label
                                htmlFor="ProductTags"
                                className="form-label fw-semibold"
                              >
                                Option Values
                              </label>
                              <div className=" border rounded-1">
                                {optionvaluetags[index] !== undefined && (
                                  <TagsInput
                                    value={optionvaluetags[index]}
                                    onChange={(newTags) => {
                                      setOptionValueTags(
                                        (prevOptionValueTags) => {
                                          const updatedTags = [
                                            ...prevOptionValueTags,
                                          ];
                                          updatedTags[index] = newTags;
                                          return updatedTags;
                                        }
                                      );
                                      setFieldValue(`Options[${index}].OptionValue`, newTags.join(","))
                                    }}
                                    inputProps={{
                                      id: `OptionValue${index}`, // Add index to id attribute
                                      className: "form-control",
                                      name: `Options[${index}].OptionValue`, // Add index to name attribute
                                      placeholder: "Enter OptionValue here",
                                      disabled: viewMode,
                                    }}
                                  />
                                )}
                              </div>
                              <ErrorMessage
                                name={`Options[${index}].OptionValue`}
                                component="div"
                                className="mt-1 error"
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            {index === values.Options.length - 1 ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                style={{ marginTop: "30px" }}
                                onClick={() => {
                                  setFieldValue("Options", [
                                    ...values.Options,
                                    { OptionName: "", OptionValue: ""},
                                  ]);
                                  setOptionValueTags((prevTags) => [...prevTags, []]);
                                }}
                              >
                                <i className="ti ti-circle-plus"></i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{ marginTop: "30px" }}
                                onClick={() => {
                                  const updatedOptions = [...values.Options];
                                  updatedOptions.splice(index, 1);
                                  setFieldValue("Options", updatedOptions);

                                  setOptionValueTags((prevTags) => {
                                    var newTags = [...prevTags];
                                    newTags.splice(index, 1);
                                    return newTags;
                                  });
                                }}
                              >
                                <i className="ti ti-circle-minus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}

                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Product FAQ's
                    </h5>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="HasFAQs"
                            name="HasFAQs"
                            className="form-check-input"
                            disabled={viewMode}
                            checked={values.HasFAQs}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFieldValue("HasFAQs", isChecked); // Update the value in Formik state
                              if (!isChecked) {
                                setFieldValue("ProductFaqs", []); // Clear ProductFaqs array
                              } else {
                                setFieldValue("ProductFaqs", [{ Question: "", Answer: "", SortOrder: "" }]); // Add a default FAQ entry
                              }
                            }}
                          />
                          <label
                            htmlFor="HasFAQs"
                            className="fw-semibold form-check-label"
                          >
                            Has FAQ's ?
                          </label>
                        </div>
                        <ErrorMessage
                          name="HasFAQs"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    {values.HasFAQs &&
                      values.ProductFaqs.map((option, index) => (
                        <div className="row pb-4" key={index}>
                          <h6 className="mb-3">FAQ {index + 1}</h6>
                          <div className="col-md-10">
                            <div className="mb-4">
                              <div className="input-group border rounded-1">
                                <span
                                  className="input-group-text bg-transparent px-6 border-0"
                                  id="basic-addon1"
                                >
                                  <i className="ti ti-link fs-6"></i>
                                </span>
                                <Field
                                  type="text"
                                  id={`Question${index}`} // Add index to id attribute
                                  name={`ProductFaqs[${index}].Question`} // Add index to name attribute
                                  className="form-control border-0 ps-2"
                                  placeholder={`Question ${index + 1}`}
                                  value={values.ProductFaqs[index].Question} // Use the value from optionFields for the initial value
                                  onChange={(e) =>
                                    setFieldValue(`ProductFaqs[${index}].Question`, e.target.value)
                                  }
                                  disabled={viewMode}
                                />
                              </div>
                              <ErrorMessage
                                name={`ProductFaqs[${index}].Question`}
                                component="div"
                                className="mt-1 error"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="mb-4">
                              <div className="input-group border rounded-1">
                                <span
                                  className="input-group-text bg-transparent px-6 border-0"
                                  id="basic-addon1"
                                >
                                  <i className="ti ti-link fs-6"></i>
                                </span>
                                <Field
                                  type="text"
                                  id={`SortOrder${index}`} // Add index to id attribute
                                  name={`ProductFaqs[${index}].SortOrder`} // Add index to name attribute
                                  className="form-control border-0 ps-2"
                                  placeholder="Order"
                                  value={values.ProductFaqs[index].SortOrder} // Use the value from optionFields for the initial value
                                  onChange={(e) =>
                                    setFieldValue(`ProductFaqs[${index}].SortOrder`, e.target.value)
                                  }
                                  disabled={viewMode}
                                />
                              </div>
                              <ErrorMessage
                                name={`ProductFaqs[${index}].SortOrder`}
                                component="div"
                                className="mt-1 error"
                              />
                            </div>
                          </div>
                          <div className="col-md-11">
                            <div className="mb-4">
                              <div className="input-group border rounded-1">
                                <span
                                  className="input-group-text bg-transparent px-6 border-0"
                                  id="basic-addon1"
                                >
                                  <i className="ti ti-link fs-6"></i>
                                </span>
                                <Field
                                  type="text"
                                  id={`Answer${index}`} // Add index to id attribute
                                  name={`ProductFaqs[${index}].Answer`} // Add index to name attribute
                                  className="form-control border-0 ps-2"
                                  placeholder={`Answer ${index + 1}`}
                                  value={values.ProductFaqs[index].Answer} // Use the value from optionFields for the initial value
                                  onChange={(e) =>
                                    setFieldValue(`ProductFaqs[${index}].Answer`, e.target.value)
                                  }
                                  disabled={viewMode}
                                />
                              </div>
                              <ErrorMessage
                                name={`ProductFaqs[${index}].Answer`}
                                component="div"
                                className="mt-1 error"
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            {index === values.ProductFaqs.length - 1 ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() =>
                                  setFieldValue("ProductFaqs", [
                                    ...values.ProductFaqs,
                                    { Question: "", Answer: "", SortOrder: "" },
                                  ])
                                }
                              >
                                <i className="ti ti-circle-plus"></i>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  const updatedFAQs = [...values.ProductFaqs];
                                  updatedFAQs.splice(index, 1);
                                  setFieldValue("ProductFaqs", updatedFAQs);
                                }}
                              >
                                <i className="ti ti-circle-minus"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      ))}

                    <h5 className="mb-4 bg-dark py-2 text-white rounded-1">
                      Buttons
                    </h5>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="BuyNow"
                            name="BuyNow"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="BuyNow"
                            className="fw-semibold form-check-label"
                          >
                            Buy Now
                          </label>
                        </div>
                        <ErrorMessage
                          name="BuyNow"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="AddToCart"
                            name="AddToCart"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="AddToCart"
                            className="fw-semibold form-check-label"
                          >
                            Add To Cart
                          </label>
                        </div>
                        <ErrorMessage
                          name="AddToCart"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="Wishlist"
                            name="Wishlist"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="Wishlist"
                            className="fw-semibold form-check-label"
                          >
                            WishList
                          </label>
                        </div>
                        <ErrorMessage
                          name="Wishlist"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="Enquiry"
                            name="Enquiry"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="Enquiry"
                            className="fw-semibold form-check-label"
                          >
                            Enquiry
                          </label>
                        </div>
                        <ErrorMessage
                          name="Enquiry"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-4">
                        <div className="form-check">
                          <Field
                            type="checkbox"
                            id="Whatsapp"
                            name="Whatsapp"
                            className="form-check-input"
                            disabled={viewMode}
                          />
                          <label
                            htmlFor="Whatsapp"
                            className="fw-semibold form-check-label"
                          >
                            WhatsApp
                          </label>
                        </div>
                        <ErrorMessage
                          name="Whatsapp"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <h5 className=" mb-4 bg-dark py-2 text-white rounded-1">
                      Seo
                    </h5>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="Title"
                          className="form-label fw-semibold"
                        >
                          Title
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="Title"
                            name="Title"
                            className="form-control border-0 ps-2"
                            placeholder="Title"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="Title"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-4">
                        <label
                          htmlFor="MetaKeywords"
                          className="form-label fw-semibold"
                        >
                          Meta Keywords
                        </label>
                        <div className=" border rounded-1">
                          <TagsInput
                            value={tags}
                            onChange={(newTags) => {
                              setTags(newTags);
                              setFieldValue("MetaKeywords", newTags.join(","));
                            }}
                            inputProps={{
                              id: "MetaKeywords",
                              className: "form-control",
                              name: "MetaKeywords",
                              placeholder: "Enter MetaKeywords here",
                              disabled: viewMode,
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="MetaKeywords"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="MetaDescription"
                          className="form-label fw-semibold"
                        >
                          Meta Description
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-link fs-6"></i>
                          </span>
                          <Field
                            type="text"
                            id="MetaDescription"
                            name="MetaDescription"
                            className="form-control border-0 ps-2"
                            placeholder="MetaDescription"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="MetaDescription"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label
                          htmlFor="ProductTags"
                          className="form-label fw-semibold"
                        >
                          Product Tags
                        </label>
                        <div className=" border rounded-1">
                          <TagsInput
                            value={producttags}
                            onChange={(newTags) => {
                              setProductTags(newTags);
                              setFieldValue("ProductTags", newTags.join(","));
                            }}
                            inputProps={{
                              id: "ProductTags",
                              className: "form-control",
                              name: "ProductTags",
                              placeholder: "Enter ProductTags here",
                              disabled: viewMode,
                            }}
                          />
                        </div>
                        <ErrorMessage
                          name="ProductTags"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="mb-4">
                        <label
                          htmlFor="SortOrder"
                          className="form-label fw-semibold"
                        >
                          Sort Order
                        </label>
                        <div className="input-group border rounded-1">
                          <span
                            className="input-group-text bg-transparent px-6 border-0"
                            id="basic-addon1"
                          >
                            <i className="ti ti-arrows-sort fs-6"></i>
                          </span>
                          <Field
                            type="number"
                            id="SortOrder"
                            name="SortOrder"
                            className="form-control"
                            disabled={viewMode}
                          />
                        </div>
                        <ErrorMessage
                          name="SortOrder"
                          component="div"
                          className="mt-1 error"
                        />
                      </div>
                    </div>
                  </div>
                  {viewMode ? (
                    <div className="py-3"></div>
                  ) : (
                    <div className="action-form">
                      <div className="mb-3 mb-0 text-end">
                        <button
                          type="submit"
                          className="btn btn-info rounded-pill px-4 waves-effect waves-light"
                        >
                          Save
                        </button>
                        <Link
                          to="/product"
                          className="btn btn-dark rounded-pill px-4 waves-effect waves-light ms-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
