import { act } from "react-dom/test-utils";
import {
  FETCH_BOUTIQUE_FAILURE,
  FETCH_BOUTIQUE_SUCCESS,
  FETCH_BOUTIQUE_REQUEST,
  ADD_BOUTIQUE_REQUEST,
  ADD_BOUTIQUE_SUCCESS,
  ADD_BOUTIQUE_FAILURE,
  DELETE_BOUTIQUE_REQUEST,
  DELETE_BOUTIQUE_SUCCESS,
  DELETE_BOUTIQUE_FAILURE,
  MULTIPLE_DELETE_BOUTIQUE_SUCCESS,
  UPDATE_BOUTIQUE_REQUEST,
  UPDATE_BOUTIQUE_SUCCESS,
  UPDATE_BOUTIQUE_FAILURE
} from "../actions/boutiqueActions";

// Initial state
const initialState = {
  boutique: [],
  loading: false,
  error: null,
};

// Reducer
const boutiqueReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BOUTIQUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_BOUTIQUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case DELETE_BOUTIQUE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };

        case UPDATE_BOUTIQUE_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
          

    case FETCH_BOUTIQUE_SUCCESS:
      return {
        ...state,
        loading: false,
        boutique: action.payload,
      };
    case ADD_BOUTIQUE_SUCCESS:
      return {
        ...state,
        loading: false,
        boutique: action.payload,
      };
      
      case DELETE_BOUTIQUE_SUCCESS:
      return {
        ...state,
        loading: false,
        boutique: state.boutique.filter(entry => entry.id !== action.payload.deletedId),
      };

      case MULTIPLE_DELETE_BOUTIQUE_SUCCESS:
        console.log(action.payload)
        const deletedIds = action.payload;
        const updatedBoutique = state.boutique.filter(entry => !deletedIds.includes(entry.id));
        return {
          ...state,
          loading: false,
          boutique: updatedBoutique,
        };

        case UPDATE_BOUTIQUE_SUCCESS:
          return {
            ...state,
            loading: false,
            boutique: action.payload,
          };

      
    case FETCH_BOUTIQUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_BOUTIQUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case DELETE_BOUTIQUE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

      case UPDATE_BOUTIQUE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    default:
      return state;
  }
};

export default boutiqueReducer;
