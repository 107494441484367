import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_FEEDBACK_URL, DELETE_FEEDBACK_URL, MULTIPLE_FEEDBACK_DELETE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_FEEDBACK_REQUEST = 'FETCH_FEEDBACK_REQUEST';
export const FETCH_FEEDBACK_SUCCESS = 'FETCH_FEEDBACK_SUCCESS';
export const FETCH_FEEDBACK_FAILURE = 'FETCH_FEEDBACK_FAILURE';

export const DELETE_FEEDBACK_REQUEST = 'DELETE_FEEDBACK_REQUEST';
export const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
export const DELETE_FEEDBACK_FAILURE = 'DELETE_FEEDBACK_FAILURE';

export const MULTIPLE_DELETE_FEEDBACK_SUCCESS = 'MULTIPLE_DELETE_FEEDBACK_SUCCESS';


export const fetchFeedbackRequest = (id) => async (dispatch) => {
    dispatch({ type: FETCH_FEEDBACK_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_FEEDBACK_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (id) {
      const feedback = response.data;
      dispatch({
        type: FETCH_FEEDBACK_SUCCESS,
        payload: [feedback],
      });
    } else {
        const feedbackList = response.data;
      dispatch({
          type: FETCH_FEEDBACK_SUCCESS,
          payload: feedbackList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FEEDBACK_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchFeedbackSuccess = (feedback) => ({
      type: FETCH_FEEDBACK_SUCCESS,
      payload: feedback,
  });
  
  export const fetchFeedbackFailure = (error) => ({
      type: FETCH_FEEDBACK_FAILURE,
    payload: error,
  });
  

  export const deleteFeedbackRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_FEEDBACK_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
        await axios.delete(`${DELETE_FEEDBACK_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
        dispatch({ type: DELETE_FEEDBACK_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
          type: DELETE_FEEDBACK_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Feedback');
    }
  };

  export const deleteFeedbackSuccess = () => ({
      type: DELETE_FEEDBACK_SUCCESS,
  });
  
  export const deleteFeedbackFailure = (error) => ({
      type: DELETE_FEEDBACK_FAILURE,
    payload: error,
  });


  export const multipleDeleteFeedbackRequest = (ids) => async (dispatch) => {
      dispatch({ type: DELETE_FEEDBACK_REQUEST });
    const token = localStorage.getItem('token');
    try {
        const response = await axios.post(MULTIPLE_FEEDBACK_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
        dispatch({ type: MULTIPLE_DELETE_FEEDBACK_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
          type: DELETE_FEEDBACK_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };

  
  
