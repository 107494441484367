import { React, useEffect } from 'react';
import defaultLogo from '../assets/images/logos/dark-logo.svg'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogoRequest } from '../redux_store/actions/logoActions';
import { BASE_URL } from '../apis/url_constants';



const SideBar = () => {

    const dispatch = useDispatch();

    const { logo } = useSelector((state) => state.logo);

    useEffect(() => {
        dispatch(fetchLogoRequest());
    }, [dispatch]);

    const handleSidebarToggle = () => {
        document.querySelector(".sidebartoggler").addEventListener("click", function () {
            var mainWrapper = document.getElementById("main-wrapper");
            mainWrapper.classList.toggle("mini-sidebar");

            if (mainWrapper.classList.contains("mini-sidebar")) {
                document.querySelector(".sidebartoggler").checked = true;
                mainWrapper.setAttribute("data-sidebartype", "mini-sidebar");
            } else {
                document.querySelector(".sidebartoggler").checked = false;
                mainWrapper.setAttribute("data-sidebartype", "full");
            }
        });

        document.querySelector(".sidebartoggler").addEventListener("click", function () {
            document.getElementById("main-wrapper").classList.toggle("show-sidebar");
        });
    }

    return (
        <>
            <aside className="left-sidebar">
                <div>
                    <div className="brand-logo d-flex align-items-center justify-content-between py-4">
                        <Link to="/" className="text-nowrap logo-img">
                            <img src={logo?.headerLogo ? `${BASE_URL}${logo.headerLogo}` : defaultLogo} width="180" alt="" />
                        </Link>
                        <div className="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse" onClick={handleSidebarToggle}>
                            <i className="ti ti-x fs-8"></i>
                        </div>
                    </div>
                    <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
                        <ul id="sidebarnav" style={{
                            paddingTop: "10px",
                            paddingBottom: "50px",
                        }}>
                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li className="sidebar-item">
                                    <Link to="/" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-layout-dashboard"></i>
                                        </span>
                                        <span className="hide-menu">Dashboard</span>
                                    </Link>
                                </li>
                            </div>
                            <li className="nav-small-cap">

                            </li>

                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/businessProfile" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-briefcase"></i>
                                        </span>
                                        <span className="hide-menu">Business Profile</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/about" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-info-circle"></i>
                                        </span>
                                        <span className="hide-menu">About</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/team" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-users"></i>
                                        </span>
                                        <span className="hide-menu">Our Team</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/logo" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-badge-tm"></i>
                                        </span>
                                        <span className="hide-menu">Logo's</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Gallery" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-photo"></i>
                                        </span>
                                        <span className="hide-menu">Gallery Images</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to="/Slider" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-photo-down"></i>
                                        </span>
                                        <span className="hide-menu">Slider</span>
                                    </Link>
                                </li>
                            </div>
                            <li className="nav-small-cap">

                            </li>



                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/testimonial" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-quote"></i>
                                        </span>
                                        <span className="hide-menu">Testimonials</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/enquiry" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-headset"></i>
                                        </span>
                                        <span className="hide-menu">Enquiries</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/dressestoorderenquiry" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-headset"></i>
                                        </span>
                                        <span className="hide-menu">Dresses To Order Enquiry</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/feedback" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-headset"></i>
                                        </span>
                                        <span className="hide-menu">FeedBacks</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/productReview" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-message-dots"></i>
                                        </span>
                                        <span className="hide-menu">Product Review</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to="/subscriber" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-hand-click"></i>
                                        </span>
                                        <span className="hide-menu">NewsLetter Subscriber</span>
                                    </Link>
                                </li>
                            </div>
                            <li className="nav-small-cap">

                            </li>

                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/product" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-barcode"></i>
                                        </span>
                                        <span className="hide-menu">Products</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Category" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-category-2"></i>
                                        </span>
                                        <span className="hide-menu">Product Category</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/subCategory" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-category"></i>
                                        </span>
                                        <span className="hide-menu">Product SubCategory</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Boutique" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-category-2"></i>
                                        </span>
                                        <span className="hide-menu">Product Boutique</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Coupon" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-ticket"></i>
                                        </span>
                                        <span className="hide-menu">Coupons</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Deal" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-discount"></i>
                                        </span>
                                        <span className="hide-menu">Offers</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/order" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-shopping-cart"></i>
                                        </span>
                                        <span className="hide-menu">Orders</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/abandonedCart" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-shopping-cart-pause"></i>
                                        </span>
                                        <span className="hide-menu">Abandoned Cart</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/abandonedWishlist" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-shopping-cart-heart"></i>
                                        </span>
                                        <span className="hide-menu">Abandoned Wishlist</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/blogPost" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-article"></i>
                                        </span>
                                        <span className="hide-menu">Blog Post</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/customPages" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-file-info"></i>
                                        </span>
                                        <span className="hide-menu">Custom Page's</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/faqs" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-article"></i>
                                        </span>
                                        <span className="hide-menu">FAQ's</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to="/videos" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-file-info"></i>
                                        </span>
                                        <span className="hide-menu">Videos</span>
                                    </Link>
                                </li>
                            </div>
                            <li className="nav-small-cap">

                            </li>

                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/Taxes" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-receipt-tax"></i>
                                        </span>
                                        <span className="hide-menu">Product Taxes</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to="/navigationMenu" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-arrows-split-2"></i>
                                        </span>
                                        <span className="hide-menu">Navigation Menu</span>
                                    </Link>
                                </li>
                            </div>


                            <li className="nav-small-cap">

                            </li>
                            <div style={{
                                borderRadius: "10px",
                                padding: "5px",
                                boxShadow: " 0px 1px 6px 3px lightgray",
                            }}>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/seo" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-briefcase"></i>
                                        </span>
                                        <span className="hide-menu">SEO</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/webSetting" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-briefcase"></i>
                                        </span>
                                        <span className="hide-menu">Home Page Settings</span>
                                    </Link>
                                </li>
                                <li style={{
                                    borderBottom: "1px solid lightgray",
                                }} className="sidebar-item">
                                    <Link to="/colorCombination" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-briefcase"></i>
                                        </span>
                                        <span className="hide-menu">Color Combination</span>
                                    </Link>
                                </li>
                                <li className="sidebar-item">
                                    <Link to="/user" className="sidebar-link" aria-expanded="false">
                                        <span>
                                            <i className="ti ti-briefcase"></i>
                                        </span>
                                        <span className="hide-menu">User's</span>
                                    </Link>
                                </li>
                            </div>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}

export default SideBar